/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    img: "img",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/sudden/'], ['en', 'https://www.hear.com/hearing-loss/sudden/'], ['en-US', 'https://www.hear.com/hearing-loss/sudden/'], ['en-CA', 'https://ca.hear.com/hearing-loss/sudden/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have sudden hearing loss in one ear, you may be experiencing sensorineural hearing loss. As the name implies, sudden loss of hearing can occur suddenly or develops within a few hours, and it affects the inner ear. The degree of sudden hearing loss varies depending on certain health factors. It is still unclear what causes sudden hearing loss, and the medical world is still searching for ways to better understand this phenomenon."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The effects of sudden hearing loss range from mild to severe, and can potentially become a permanent condition. Sudden hearing loss may also occur together with tinnitus. We recommend that you consult a doctor immediately in the event of suspected sudden hearing loss. If symptoms persist for a long period of time and have already led to a hearing deficit, only hearing aids can provide effective relief."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The information below will give you a better understanding of sudden hearing loss and what you should do about it. However, if you suspect that you are experiencing signs of sudden hearing loss, we highly recommend you contact your doctor as soon as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-symptoms-of-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-symptoms-of-sudden-hearing-loss",
    "aria-label": "the symptoms of sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Symptoms of Sudden Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear pressure and/or tinnitus are the first signs of a sudden sensorineural hearing loss. Symptoms usually occur at once or after a few days, usually in one ear. The severity of the symptoms may vary significantly. In worst case scenarios, permanent deafness may be possible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/inside-of-ear-cochlea-canal.jpg",
    alt: "Cochlea canal",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The symptoms for sudden hearing loss include:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Occurrence of hearing loss without a recognizable cause"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The absence of earache"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing loss in one ear only"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The accompanying symptoms include:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dizziness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Loss of sensation in the outer ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear pressure"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tinnitus"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Is sudden hearing loss the same as tinnitus?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is a common misconception among many people that tinnitus is the same as sensorineural hearing loss. However, this is not the case. Tinnitus is considered a symptom. It is possible for both to occur at the same time. With chronic tinnitus, sudden loss of hearing may get better, but you may still experience ringing in the ears. Nonetheless, the two conditions are not directly linked to each other."), "\n", React.createElement(ButtonCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "recognizing-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#recognizing-sudden-hearing-loss",
    "aria-label": "recognizing sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Recognizing sudden hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to current medical standards, a diagnosis for sudden hearing loss is determined through the process of elimination. Your ENT specialist can use ear microscopy and a hearing test to ascertain whether you have a sudden hearing loss or another illness. Various examination procedures (e.g. blood pressure measurement, blood tests, ear microscopy or ultrasound, and MRI examinations) are performed to obtain clarification. Your ENT specialist, for example, can use otoscopy to discover whether other external characteristics, such as obstructions and injuries in the ear canal, should be excluded. Using hearing tests (tuning fork test, pure-tone audiometry), the doctor determines the extent and localization (inner or middle ear) of the hearing impairment. A doctor can best determine the classification of sudden hearing loss into high-, mid- and low-frequency or pancochlear sensorineural hearing loss by performing a pure-tone audiogram during the diagnostic process."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-pancochlear-sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-pancochlear-sensorineural-hearing-loss",
    "aria-label": "what is pancochlear sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is pancochlear sensorineural hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The word pancochlear is divided into the two terms: pan (meaning all-inclusive) and cochlea (meaning the inner ear structure). Therefore, this kind of hearing impairment is one that affects the entire structure of the inner ear (or cochlea)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A patient’s medical history is an important diagnostic component. These records contain important information that may indicate sudden hearing loss and its possible cause. If the medical history does not reveal any specific reasons for the sudden hearing loss, other diagnostic methods can be used, including the Fowler’s pure-tone audiometry, brainstem electrical response audiometry (BERA), or the measurement of otoacoustic emissions (OAE). These examinations serve to exclude a diseased acoustic nerve as the cause of impaired hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Additional information on hearing tests, in the event of sudden hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How does the hearing test work?"), "\nDuring the Fowler test, sounds are sent to both ears. The volume level varies and is adjusted with the help of the patient (subjective hearing test) until they can hear equally well with both ears. An alignment with the healthy ear serves to determine the audibility threshold and the extent of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How does brainstem electrical response audiometry work?"), "\nBrainstem electrical response audiometry (BERA) measures the sound vibrations travelling from the brainstem to the cerebral cortex. If vibrations are measurable in the cerebral cortex, it is usually assumed that the cochlea is not affected. This method assesses hearing impairment regardless of the affected person’s subjective impression (objective hearing test)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How does otoacoustic emission work?"), "\nDuring the otoacoustic emission (OAE) test, a tiny probe equipped with a loudspeaker and microphone is inserted into the ear canal. If the sound vibrations created by the probe penetrate a healthy inner ear, a measurable reaction occurs. This hearing test is often conducted on infants as well as adults, since it does not require the participation of the affected person (objective hearing test)."), "\n", React.createElement(ButtonCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-of-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-of-sudden-hearing-loss",
    "aria-label": "treatment of sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment of sudden hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To treat sudden hearing loss, time is of the essence: the earlier treatment is started, the better the prognosis. In a best-case scenario, the sudden hearing loss is treated by an ENT specialist or at an ENT clinic within the first 24 hours. The treatment includes the stimulation of the inner ear’s circulation and the elimination of possible triggers. Here are the most common forms of treatment, in the event of sudden hearing loss:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The administration of circulation-enhancing (rheological) medication")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the event of sudden hearing loss, the inner ear’s blood circulation is reduced or impaired. Blood supplies the inner ear with the required nutrients. If circulation is inhibited, the sensory hair cells of the ear are permanently damaged. Circulation can be improved with the administration of circulation-enhancing medication, which can also include cortisone to prevent any swelling (anti-edema treatment)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Treatment with local anesthesia to block inhibited nerves")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The local anesthetics procaine and lidocaine are frequently used to treat sudden hearing loss (inotropic therapy). Their administration blocks nerves that potentially lead to vascular constriction. This treatment should always occur under a doctor’s clinical observation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing aids as treatment for sudden hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sudden hearing loss causes permanent damage to the sensory and hair cells, potentially leading to permanent hearing impairment. Persistent hearing loss, combined with tinnitus, should be treated with hearing aids that can generate a soft noise to distract the individual experiencing tinnitus symptoms. This function is referred to as a ‘noiser’ and is ideal for very quiet hearing environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Treatment in an oxygen pressure chamber")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another treatment option is hyperbaric oxygen therapy (treatment in an oxygen pressure chamber). This is a good method of treatment, but according to the criteria of evidence-based medicine, it may not be the most effective."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Treatment for particularly severe cases of sudden hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Dialysis (apheresis) is another treatment option if the sudden hearing loss is particularly severe. In technical terms, this procedure is called heparin-induced extracorporeal LDL precipitation (H.E.L.P.) apheresis, whereby the blood is cleansed of coagulation-promoting substances. This improves flow characteristics and circulation. Severe cases require inpatient treatment, but outpatient treatment by a specialist is usually possible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Treatment in the event of obesity and diabetes"), "\nTreating risk factors for arteriosclerosis (the build-up of fats, cholesterol, and other substances in and on the artery walls) is extremely important in the long run. Lifestyle changes that can facilitate treatment include: weight loss combined with a low-fat diet and exercise, the correct adjustment of blood sugar levels, and avoiding nicotine, coffee and alcohol. Implementing these changes is imperative for effective treatment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Alternative forms of treatment"), "\nAlternative forms of treatment are available. However, their effectiveness has not been proven, and traditional health care has, in some cases, rejected them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Shortly after sudden hearing loss, the focus is on the reduction of risk factors – especially cardiovascular risk factors, such as obesity, high blood pressure, high cholesterol levels and diabetes (see above). You need to take steps to ensure that your personal lifestyle is less stressful in order to prevent situations that are difficult to cope with. If sudden hearing loss occurred more than a year ago, the only option is to alleviate it with the help of a hearing aid. If this applies to you, the next important step is to speak to an expert about your personal options."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How can hearing aids help in the event of sudden hearing loss?"), "\nHearing aids can only help if hearing is already impaired in at least one ear. Wearing these assisted hearing devices will help improve the way you perceive and alleviate the effects of sudden hearing loss. Ideally, the appropriate medication (e.g. cortisone) should be used within 24 hours after you notice symptoms. If treatment does not follow within this period, we recommend the use of hearing aids to provide relief. If sudden loss of hearing occurs together with tinnitus, the special functions in most hearing aids can help treat both conditions."), "\n", React.createElement(ButtonCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "complications-following-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#complications-following-sudden-hearing-loss",
    "aria-label": "complications following sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Complications following sudden hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sudden hearing loss may result in various complications that reduce hearing capacity. There is always the risk of hearing impairment or even complete deafness. Depending on individual circumstances, these can be of short-term or permanent. A possible long-term effect is the development of tinnitus (a permanent buzzing or ringing in the ears) after sudden hearing loss. This can be expressed in various tones and be accompanied by a permanent feeling of pressure in the ear. In some cases, sudden hearing loss is followed by a feeling of internal restlessness and a racing heartbeat."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Which individual circumstances can lead to sudden hearing loss?"), "\nThe exact causes of sudden hearing loss are unknown, although there are some theories deemed probable by ENT medicine. Nevertheless, it is a fact that the inner ear’s circulation is reduced during sudden hearing loss. One particular trigger is stress. A hectic environment or severe time pressure at work could lead to circulatory disorders, potentially leading to sudden hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-causes-of-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-causes-of-sudden-hearing-loss",
    "aria-label": "the causes of sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The causes of sudden hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our knowledge concerning the reasons for hearing loss is limited. Notwithstanding this limitation, one possible reason has to do with circulatory disorders in the smallest vessels of the inner ear (microcirculation). Other contributing factors include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stress"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Viral infections or reactivations (e.g. herpes or chickenpox)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Arteriosclerosis and subsequent circulatory disorders"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Metabolic disorders such as diabetes or high cholesterol levels"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A ruptured oval or round window"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cervical spine injury (e.g. whiplash)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Elevated platelet aggregation (blood clots)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Occlusion (thrombosis) of the inner ear’s vessels"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Autoimmune diseases"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Previous acute otitis media (middle ear infection)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "We can assume that there are additional causes for the occurrence of sudden hearing loss, which are frequently discussed by specialists and experts."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Sudden idiopathic hearing loss"), "\nIdiopathic means “without recognizable cause.” This is the most common and sudden type of hearing loss. Extensive examination cannot determine an organic cause."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Stress-related sudden hearing loss"), "\nStress is a frequent trigger for sudden hearing loss. It is assumed that the stress hormone adrenaline causes the small vessels to become narrower. The exact pathogenesis – i.e. the creation process of sudden hearing loss – is unknown. If you have suffered sudden hearing loss, it is imperative that you learn to reduce your level of stress in your daily activities so that you can better cope with demanding situations. Regular exercise can also be helpful in eliminating stress."), "\n", React.createElement(ButtonCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
